import { FC, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { StatusEnum } from '@/apolloGenerated';
import { TabItem, Tabs } from '@letsdance/ui-kit';
import { useEventsFiltersStore } from '@pages/QrScan/libs/store';
import { EventsList } from '@widgets/QrScan';

import styles from './styles.module.scss';

export const EventListView: FC = () => {
  const { search } = useEventsFiltersStore();
  const [debounceSearch] = useDebounce(search, 500);
  const [tab, setTab] = useState<StatusEnum>(StatusEnum.Active);

  return (
    <div className={styles.eventsList}>
      <Tabs
        className={styles.eventsList__tabs}
        initValue={tab}
        onChange={setTab}>
        <TabItem value={StatusEnum.Active} label="Активные" />
        <TabItem value={StatusEnum.Archive} label="Прошедшие" />
      </Tabs>
      <EventsList search={debounceSearch} status={tab} />
    </div>
  );
};
